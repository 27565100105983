import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styles from './MainLayout.module.scss';
import Sidebar from 'components/Sidebar/Sidebar';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import TradingScreen from '../../screens/TradingScreen/TradingScreen';
import Header from 'components/Header/Header';
import PageProfile from '../../old/components/page/UpdateProfileInfo';
import PageDeposit from '../../old/components/page/Deposit';
import PageWithdraw from '../../old/components/page/Withdraw';
import config from 'config';
import PageUploadDocuments from '../../old/components/page/UploadDocuments';
import PageAccountStatement from '../../old/components/page/AccountStatement/AccountStatement';
import CoinsExchange from '../../old/components/page/CoinsExchange/CoinsExchange';
import PageMessageInner from '../../old/components/page/MessageInner';
import PageMessages from '../../old/components/page/Messages';
import PageLoyaltyProfile from '../../old/components/page/LoyaltyProfile/LoyaltyProfile';
import { useIsMobile } from 'utils/responsiveHelpers';
import MobileTradingScreen from '../../screens/MobileTradingScreen/MobileTradingScreen';
import { useStore } from 'react-redux';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
import SettingsScreen from '../../screens/SettingsScreen/SettingsScreen';
import ModalLowMargin from 'components/ModalLowMargin/ModalLowMargin';
import { supportsCoinsSelector } from 'wintrado-api/src/selectors';
import PageNotFoundScreen from '../../screens/PageNotFoundScreen/PageNotFoundScreen';
import CustomIframeScreen from '../../screens/CustomIframeScreen/CustomIframeScreen';
const MainLayout = () => {
    var _a;
    const isMobile = useIsMobile();
    const history = useHistory();
    const store = useStore();
    const dispatch = useAppDispatch();
    const supportsCoins = useAppSelector(supportsCoinsSelector);
    const { hasMessages } = config.cashier;
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.key === 'o') {
                e.stopPropagation();
                e.preventDefault();
                if (!history.location.pathname.includes('/trading/place-order')) {
                    history.push('/trading/place-order');
                }
            }
            else if (e.key === 'Escape') {
                if (store.getState().proTradingScreen.crosshairPriceSelection.enabled) {
                    e.stopPropagation();
                    e.preventDefault();
                    dispatch(actions.disableCrosshairPriceSelection());
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [dispatch, history, store]);
    useEffect(() => {
        dispatch(actions.loginRequest());
    }, [dispatch]);
    return (_jsxs("div", Object.assign({ className: styles.mainLayout }, { children: [!isMobile && _jsx(Sidebar, {}), _jsxs("div", Object.assign({ className: styles.mainWrapper }, { children: [_jsx(Header, {}), _jsx("main", Object.assign({ className: styles.main }, { children: _jsxs(Switch, { children: [_jsx(Route, Object.assign({ exact: true, path: "/" }, { children: _jsx(Redirect, { to: '/trading' }) })), _jsx(Route, Object.assign({ exact: false, path: "/trading" }, { children: isMobile ? _jsx(MobileTradingScreen, {}) : _jsx(TradingScreen, {}) })), _jsx(Route, Object.assign({ path: "/settings/profile" }, { children: _jsx(PageProfile, {}) })), _jsx(Route, Object.assign({ path: "/settings" }, { children: _jsx(SettingsScreen, {}) })), _jsx(Route, Object.assign({ path: "/deposit" }, { children: _jsx(PageDeposit, {}) })), _jsx(Route, Object.assign({ path: "/withdraw" }, { children: _jsx(PageWithdraw, {}) })), config.cashier && config.cashier.uploadDocumentsURL && (_jsx(Route, Object.assign({ path: "/upload-documents" }, { children: _jsx(PageUploadDocuments, {}) }))), _jsx(Route, Object.assign({ path: "/account-statement" }, { children: _jsx(PageAccountStatement, {}) })), hasMessages && [
                                    _jsx(Route, Object.assign({ path: "/messages/:id" }, { children: _jsx(PageMessageInner, {}) }), "messages_id"),
                                    _jsx(Route, Object.assign({ exact: true, path: "/messages" }, { children: _jsx(PageMessages, {}) }), "messages"),
                                ], supportsCoins && [
                                    _jsx(Route, Object.assign({ path: "/loyalty" }, { children: _jsx(PageLoyaltyProfile, {}) }), "loyalty"),
                                    _jsx(Route, Object.assign({ path: "/coins" }, { children: _jsx(CoinsExchange, {}) }), "coins"),
                                ], (_a = config.customPagesConfig) === null || _a === void 0 ? void 0 : _a.map((route) => (_jsx(Route, Object.assign({ path: route.pathName }, { children: _jsx(CustomIframeScreen, { url: route.url }) }), route.pathName))), _jsx(Route, Object.assign({ path: "*" }, { children: _jsx(PageNotFoundScreen, {}) }))] }) })), _jsx(ModalLowMargin, {})] }))] })));
};
export default React.memo(MainLayout);

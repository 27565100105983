import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './VolumeInputField.module.scss';
import classNames from 'classnames';
import _t from 'counterpart';
import { clamp, isSet } from '../../helpers';
import { FaPlus } from 'react-icons/fa6';
import { FaMinus } from 'react-icons/fa6';
import FormError from 'components/FormError/FormError';
const getDecimalPlaces = (val) => {
    if (!isSet(val)) {
        return 0;
    }
    const split = val.split('.');
    if (split.length < 2) {
        return 0;
    }
    return split[1].length;
};
const VolumeInputField = forwardRef((props, ref) => {
    var _a;
    const { name, setValue, error, instrument, autoFocus } = props;
    const [isLabelFloating, setIsLabelFloating] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const setSize = (value) => {
        setValue('size', value);
    };
    const inputRef = useRef(null);
    useImperativeHandle(ref, () => inputRef.current);
    const size = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value;
    const hasValue = isSet(size) && size.toString().length > 0;
    useEffect(() => {
        if (!hasValue) {
            setSize(instrument === null || instrument === void 0 ? void 0 : instrument.minOrderSize.toString());
            setIsLabelFloating(true);
        }
    }, [instrument === null || instrument === void 0 ? void 0 : instrument.minOrderSize, size]);
    const handleOnChange = (e) => {
        const target = e.target;
        setSize(target.value);
    };
    const handleOnFocus = () => {
        setIsLabelFloating(true);
        setIsFocused(true);
    };
    const handleOnBlur = () => {
        if (!hasValue) {
            setIsLabelFloating(false);
        }
        setIsFocused(false);
    };
    const handleUpdateValue = (value) => {
        setSize(value);
        setIsLabelFloating(true);
        setIsFocused(true);
    };
    const withSizeChange = (canChange) => {
        if (!hasValue) {
            return true;
        }
        if (!instrument) {
            return false;
        }
        const value = Number(size);
        return canChange(value, instrument);
    };
    const canIncreaseSize = (value, { maxOrderSize }) => {
        return value < maxOrderSize;
    };
    const canDecreaseSize = (value, { minOrderSize }) => {
        return value > minOrderSize;
    };
    const canDecrease = withSizeChange(canDecreaseSize);
    const canIncrease = withSizeChange(canIncreaseSize);
    // Edge case read: https://flaviocopes.com/javascript-decimal-arithmetics/
    const valueWithoutDecimalsIssue = (nextValue) => {
        const decimals = getDecimalPlaces(size === null || size === void 0 ? void 0 : size.toString());
        const stepDecimals = getDecimalPlaces(instrument.stepOrderSize.toString());
        const nextDecimals = getDecimalPlaces(nextValue.toString());
        if (nextDecimals > decimals) {
            const next = nextDecimals - decimals;
            if (next <= stepDecimals) {
                return nextValue.toFixed(nextDecimals);
            }
        }
        return nextValue.toFixed(decimals);
    };
    const increaseSize = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        if (canIncrease) {
            const { stepOrderSize, minOrderSize, maxOrderSize } = instrument;
            const value = !hasValue ? 0 : Number(size);
            const nextValue = value + stepOrderSize;
            const clampedValue = clamp(nextValue, minOrderSize, maxOrderSize);
            handleUpdateValue(valueWithoutDecimalsIssue(clampedValue));
        }
    };
    const decreaseSize = () => {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        if (canDecrease) {
            const { stepOrderSize, maxOrderSize, minOrderSize } = instrument;
            const value = !hasValue ? maxOrderSize + stepOrderSize : Number(size);
            const nextValue = value - stepOrderSize;
            const clampedValue = clamp(nextValue, minOrderSize, maxOrderSize);
            handleUpdateValue(valueWithoutDecimalsIssue(clampedValue));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(styles.textField, {
                    [styles.textFieldFloating]: isLabelFloating,
                    [styles.textFieldFocused]: isFocused,
                    [styles.textFieldHasError]: isSet(error),
                }) }, { children: [_jsx("label", Object.assign({ className: classNames(styles.label) }, { children: _t('order.volume_in_lots') })), _jsxs("div", Object.assign({ className: styles.volumeButtons }, { children: [_jsx("button", Object.assign({ type: "button", onClick: decreaseSize, disabled: !canDecrease }, { children: _jsx(FaMinus, { size: 16 }) })), _jsx("button", Object.assign({ type: "button", onClick: increaseSize, disabled: !canIncrease }, { children: _jsx(FaPlus, { size: 16 }) }))] })), _jsx("input", { id: name, name: name, className: styles.input, ref: inputRef, onChange: handleOnChange, onFocus: handleOnFocus, onBlur: handleOnBlur, autoFocus: autoFocus })] })), isSet(error) && _jsx(FormError, { error: error, margin: 'top' })] }));
});
export default VolumeInputField;

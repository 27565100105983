import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CustomIcon from 'components/CustomIcon/CustomIcon';
import DetailItem from 'components/InstrumentDetails/DetailItem';
import _t from 'counterpart';
import React from 'react';
import { FiClock, FiFileText, FiRepeat } from 'react-icons/fi';
import halfIcon from '../../assets/icons/half.svg?url';
import { getHumanReadableDateRange } from '../../helpers';
import styles from './InstrumentDetails.module.scss';
import { useAppSelector } from 'wintrado-api';
import * as Sentry from '@sentry/react';
const InstrumentDetails = ({ instrumentSymbol }) => {
    const instrument = useAppSelector((state) => state.instruments[instrumentSymbol]);
    const instrumentValidTradingInterval = useAppSelector((state) => state.timeCalculations.instrumentsValidTradingIntervals[instrumentSymbol]);
    if (!instrument) {
        throw new Error(`Instrument with symbol ${instrumentSymbol} not found`);
    }
    // this is just temporary. instrumentValidTradingInterval can be null if the instrument is not loaded yet, or if we pass a wrong symbol
    // this is the best way to catch this error, so we can see it in Sentry and fix it
    if (instrumentValidTradingInterval === null || instrumentValidTradingInterval === undefined) {
        Sentry.captureException(new Error(`Instrument with symbol ${instrumentSymbol} has no valid trading interval`));
    }
    const tradingHours = React.useMemo(() => {
        if (!(instrumentValidTradingInterval === null || instrumentValidTradingInterval === void 0 ? void 0 : instrumentValidTradingInterval.current)) {
            return '—';
        }
        return getHumanReadableDateRange(instrumentValidTradingInterval.current.start, instrumentValidTradingInterval.current.end);
    }, [instrumentValidTradingInterval]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("div", Object.assign({ className: styles.titleWrapper }, { children: _jsx("span", Object.assign({ className: styles.title }, { children: _t('main.details').toUpperCase() })) })), _jsxs("div", Object.assign({ className: styles.content }, { children: [_jsx(DetailItem, { icon: _jsx(FiFileText, { size: 16, className: styles.icon }), label: _t('instrument.contract_size'), value: instrument.contractSize.toLocaleString() }), _jsx(DetailItem, { icon: _jsx(CustomIcon, { src: halfIcon, alt: 'half', className: styles.icon }), label: _t('instrument.min_max_order_size'), value: `${instrument.minOrderSize} - ${instrument.maxOrderSize}` }), _jsx(DetailItem, { icon: _jsx(CustomIcon, { src: halfIcon, alt: 'half', className: styles.icon }), label: _t('instrument.step_size'), value: instrument.stepOrderSize.toString() }), _jsx(DetailItem, { icon: _jsx(FiClock, { size: 16, className: styles.icon }), label: _t('instrument.trading_hours'), value: tradingHours }), instrument.swapMode === 'POINTS' && instrument.swapLong !== null && instrument.swapShort !== null && (_jsx(DetailItem, { icon: _jsx(FiRepeat, { size: 16, className: styles.icon }), label: _t('instrument.swap_long_short'), value: `${instrument.swapLong} - ${instrument.swapShort}` }))] }))] })));
};
export default React.memo(InstrumentDetails);

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import styles from './MobileSidebar.module.scss';
import SidebarToggleButton from 'components/SidebarToggleButton/SidebarToggleButton';
import classNames from 'classnames';
import { Portal } from '@radix-ui/react-portal';
import SidebarNavItem from 'components/Sidebar/SidebarNavItem';
import { HiArrowDown, HiArrowUp, HiLogout, HiOutlineDocumentText, HiOutlineHome, HiOutlineSpeakerphone, } from 'react-icons/hi';
import { isSet } from '../../helpers';
import config from 'config';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import Spacer from 'components/Spacer/Spacer';
import _t from 'counterpart';
import { actions, useAppDispatch } from 'wintrado-api';
import MobileSidebarFooter from 'components/MobileSidebar/MobileSidebarFooter';
import { FiEye } from 'react-icons/fi';
import BrandLogo from 'components/BrandLogo/BrandLogo';
import InstrumentIcon from "../../assets/icons/instrument.svg";
const MobileSidebar = ({ className }) => {
    var _a;
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const dispatch = useAppDispatch();
    const handleLogout = useCallback((e) => {
        e.preventDefault();
        dispatch(actions.logoff({ userRequested: true, withOAuth: true }));
    }, [dispatch]);
    const handleCloseSidebar = useCallback(() => {
        setIsSidebarOpen(false);
    }, []);
    return (_jsxs("div", Object.assign({ className: classNames(styles.container, className) }, { children: [_jsx(SidebarToggleButton, { collapsed: isSidebarOpen, onToggle: setIsSidebarOpen }), _jsx(BrandLogo, { useRectangular: true }), _jsx(Spacer, { x: "extraSmall" }), _jsx(Portal, { children: _jsxs("div", Object.assign({ className: classNames(styles.sidebar, {
                        [styles.sidebarOpen]: isSidebarOpen,
                    }) }, { children: [_jsx(SidebarNavItem, { to: "/trading", exact: true, icon: _jsx(HiOutlineHome, {}), labelKey: "main.trading", onClick: handleCloseSidebar }), _jsx(SidebarNavItem, { to: "/trading/instruments", icon: _jsx(InstrumentIcon, {}), labelKey: "main.instruments", onClick: handleCloseSidebar }), _jsx(SidebarNavItem, { to: "/trading/watchlist", icon: _jsx(FiEye, {}), labelKey: "main.watchlist", onClick: handleCloseSidebar }), isSet(config.cashier.depositURL) && (_jsx(SidebarNavItem, { to: "/deposit", icon: _jsx(HiArrowUp, {}), labelKey: "main.deposit", onClick: handleCloseSidebar })), isSet(config.cashier.withdrawURL) && (_jsx(SidebarNavItem, { to: "/withdraw", icon: _jsx(HiArrowDown, {}), labelKey: "main.withdraw", onClick: handleCloseSidebar })), _jsx(SidebarNavItem, { to: "/account-statement", icon: _jsx(HiOutlineDocumentText, {}), labelKey: "main.account_statement", onClick: handleCloseSidebar }), isSet(config.affiliatePortalUrl) && (_jsx(SidebarNavItem, { to: config.affiliatePortalUrl, icon: _jsx(HiOutlineSpeakerphone, {}), labelKey: "main.marketing", onClick: handleCloseSidebar })), (_a = config.customPagesConfig) === null || _a === void 0 ? void 0 : _a.map(({ pathName, icon: Icon, titleKey }) => (_jsx(SidebarNavItem, { to: pathName, icon: _jsx(Icon, {}), labelKey: titleKey, onClick: handleCloseSidebar }, pathName))), _jsx("div", Object.assign({ className: styles.logoutWrapper }, { children: _jsxs(ButtonBase, Object.assign({ onClick: handleLogout, className: classNames(styles.logoutButton) }, { children: [_jsx(HiLogout, {}), _jsx(Spacer, { x: "medium" }), _t('main.logout')] })) })), _jsx(MobileSidebarFooter, {})] })) })] })));
};
export default React.memo(MobileSidebar);
